import "./Header.scss";
import React, { useState, useEffect } from "react";
import { User } from "oidc-client";
import logo from "./../../assets/img/logo-colored.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Company from "../../Models/Company";
import { apiService } from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { useImage } from "../../context/ImageContext";
import placeholderLogo from "./../../assets/img/placeholder-logo.png";
import Verification from "./Components/Verification";

interface HeaderProps {
  companyLogo?: string;
  authService: {
    login: () => Promise<void>;
    logout: () => Promise<void>;
    getUser: () => Promise<User | null>;
  };
}

const Header: React.FC<HeaderProps> = (props) => {
  const [user, setUser] = useState<User | null>();
  const [companyId, setCompanyId] = useState("");
  const [userCompany, setUserCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState(true);
  const [hadImage, setHadImage] = useState(false);
  const { image } = useImage();
  const [companyImage, setCompanyImage] = useState(placeholderLogo);

  const navigate = useNavigate();

  useEffect(() => {
    if (image) {
      setHadImage(true);
    }
  }, [image]);
  useEffect(() => {
    const serviceGetUser = async () => {
      const userResponse = await props.authService.getUser();
      if (userResponse) {
        setCompanyId(userResponse.profile.sub);
      } else {
        //navigate('/');
      }
      setUser(userResponse);
      return userResponse;
    };

    serviceGetUser();
  }, [props.authService, navigate]);

  useEffect(() => {
    const fetchDataAndStoreLocally = async () => {
      if (companyId !== "" || null) {
        const endpoint = `Company/${companyId}`;
        try {
          const response = await apiService.get(endpoint);
          setUserCompany(response);
          localStorage.setItem("userCompany", JSON.stringify(response));
          setCompanyImage(
            response && response.profilePictureUrl
              ? response.profilePictureUrl
              : placeholderLogo
          );
          setLoading(false);
          document.dispatchEvent(new Event("userCompanyUpdated"));
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchDataAndStoreLocally();
  }, [companyId]);

  const handleLogin = () => {
    props.authService.login();
  };

  const handleLogout = () => {
    props.authService.logout();
    localStorage.removeItem("token");
  };
  const currentUser = user ? user : null;

  useEffect(() => {
    debugger;
    if (!loading) {
      setCompanyImage(image ? image : placeholderLogo);
    }
  }, [image]);

  return (
    <>
      {userCompany !== null &&
      (userCompany.isEmailVerified == null ||
        userCompany.isEmailVerified == false) ? (
        <Verification primaryEmail={userCompany.primaryEmail} />
      ) : null}
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark rounded py-3 rounded-0">
        <div className="container">
          <NavLink to="/" className="navbar-brand col me-0">
            <img src={logo} alt="" />
          </NavLink>
          {/* <button
            className="navbar-toggler ms-auto me-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainNav"
            aria-controls="mainNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse d-lg-flex" id="mainNav">
            <ul className="navbar-nav justify-content-lg-center flex-grow-1 pt-3 pt-lg-0">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "nav-link pending"
                      : isActive
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Contact Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Index
                </a>
              </li>
            </ul>
          </div> */}
          {!loading &&
            (userCompany !== null ? (
              <div className="flex-shrink-0 dropdown">
                <a
                  href="#"
                  className="d-block link-light text-decoration-none dropdown-toggle mt-1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="companyLogoThumbnail mt-2">
                    <img
                      src={companyImage}
                      alt={userCompany ? userCompany.name : "Company"}
                    />
                  </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li className="border-0 pb-1">
                    <NavLink
                      to={"/company/" + companyId}
                      className="col d-flex align-items-center text-dark"
                    >
                      <span className="companyLogoThumbnail">
                        <img
                          src={companyImage}
                          alt={userCompany ? userCompany.name : "Company"}
                        />
                      </span>
                      <div className="text-truncate col ps-2">
                        <h5 className="mb-0 text-truncate">
                          {userCompany.name}
                        </h5>
                        {userCompany.primaryEmail && (
                          <p className="mb-0 text-truncate">
                            {userCompany.primaryEmail}
                          </p>
                        )}
                      </div>
                    </NavLink>
                  </li>
                  <li className="border-0 pt-1">
                    <NavLink
                      to="/edit-profile"
                      className="text-primary text-center d-block stretched-link"
                    >
                      Edit Company
                    </NavLink>
                  </li>
                  <li>
                    <a
                      className="dropdown-item stretched-link text-center"
                      href="javascript:void(0)"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="d-inline-flex gap-3 justify-content-end">
                <button
                  onClick={handleLogin}
                  className="btn btn-outline-primary"
                >
                  Login
                </button>
                <NavLink
                  to="/signup"
                  className="btn btn-primary ms-md-3 d-sm-inline-block d-none"
                >
                  Signup
                </NavLink>
              </div>
            ))}
        </div>
      </nav>
    </>
  );
};

export default Header;
