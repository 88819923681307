import React, { useEffect, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import UploaderImage from "./../../../assets/img/image-upload.svg";
import { apiService } from "../../../services/apiService";
import "./CompanyLogo.scss";

export default function CompanyLogo() {
  const [images, setImages] = useState<ImageListType>([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState("");
  const [formSubmission, setFormSubmission] = useState(true);
  const maxNumber = 1;
  const navigate = useNavigate();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const registerationId = params.get("id");
  useEffect(() => {
    const storedData = localStorage.getItem("userSignup");
    if (storedData !== null) {
      const items = JSON.parse(storedData);
      setUserData(items.objectId);
    }
  }, []);
  useEffect(() => {
    console.log(registerationId);
  }, [registerationId]);
  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  useEffect(() => {
    handleUpload();
  }, [images]);
  const handleUpload = async () => {
    if (images.length > 0 && registerationId) {
      const uploadedImage = images[0];
      const endpoint = "Company/UpdateImage";
      const postData = new FormData();
      postData.append("CompanyId", registerationId);

      // Append the image file to FormData
      if (uploadedImage.file) {
        postData.append("NewPicture", uploadedImage.file);
      }

      postData.append("ImageType", "0"); // Replace with the appropriate value
      postData.append("Action", "0"); // Replace with the appropriate value

      setLoading(true);
      try {
        setFormSubmission(false);
        setTimeout(async () => {
          const response = await apiService.putMultipart(endpoint, postData);
          console.log("POST Response:", response);
          setLoading(false);
          setFormSubmission(true);
          toast.success("Logo added successfully!");
        }, 1000); // 1000 milliseconds (1 second)
      } catch (error) {
        console.error("POST Error:", error);
        toast.error("Updating failed!");
        setFormSubmission(true);
        setLoading(false);
      }
    }
  };
  const removeCompanyImage = async () => {
    setFormSubmission(false);
    setTimeout(async () => {
      try {
        const endpoint = "Company/DeleteImage";
        await apiService.deleteReference(endpoint, registerationId || "");
        toast.success("Logo removed successfully!");
      } catch (error) {
        toast.error("Failed to remove Logo!");
      }
      setFormSubmission(true);
    }, 1000);
  };
  const isSubmitDisabled = images.length === 0;
  return (
    <div className="companyLogo">
      <h3>Start by adding your company logo</h3>
      <div className="crop-container text-center mt-4 mb-5">
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
          acceptType={["jpg", "png", "jpeg"]}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div className="upload__image-wrapper">
              {imageList.length === 0 ? (
                <img
                  onClick={onImageUpload}
                  {...dragProps}
                  src={UploaderImage}
                  className="img-fluid rounded-top"
                  alt=""
                />
              ) : (
                imageList.map((image, index) => (
                  <div key={index} className="image-item text-center">
                    <div className="company-logo d-inline-flex p-2 justify-content-center align-items-center">
                      <img src={image.data_url} alt="" />
                    </div>
                    {loading ? (
                      ""
                    ) : (
                      <button
                        onClick={() => {
                          onImageRemove(index);
                          removeCompanyImage();
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                      ></button>
                    )}
                  </div>
                ))
              )}
            </div>
          )}
        </ImageUploading>
      </div>
    </div>
  );
}
