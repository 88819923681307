import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import accountCreatedImage from "./../../../assets/img/account-created.svg";
import { apiService } from "../../../services/apiService";
import "./AccountCreated.scss";
import PageLoader from "../../../shared/page-loader";
interface AuthProps {
  authService: {
    login: () => Promise<void>;
    logout: () => Promise<void>;
  };
}
const AccountCreated: React.FC<AuthProps> = (props) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = localStorage.getItem("userSignup");
    if (storedData !== null) {
      const items = JSON.parse(storedData);
      setUserData(items.objectId);
      const timer = setTimeout(() => {
        setPageLoading(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, []);
  useEffect(() => {
    console.log(userData);
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [userData]);

  const handleLogin = () => {
    props.authService.login();
  };
  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <div className="AccountCreated">
      <div className="crop-container text-center my-5">
        <img src={accountCreatedImage} alt="" />
      </div>
      <h3>Congratulations! You are ready to go...</h3>
      <p>
        I have sent you an email. Please verify your account within two weeks to
        keep your company active.
      </p>

      <div className="d-grid gap-2 mt-4">
        <button className="btn btn-primary" type="button" onClick={handleLogin}>
          Login
        </button>
      </div>
    </div>
  );
};
export default AccountCreated;
