import React from "react";
import { ToastContainer, toast } from "react-toastify";
import AuthService, { IAuthService } from "./services/authService";
import { ImageProvider } from "./context/ImageContext";
import Router from "./Router";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const authService: IAuthService = new AuthService();

function App() {
  return (
    <ImageProvider>
      <Router authService={authService} />
      <ToastContainer />
    </ImageProvider>
  );
}

export default App;
