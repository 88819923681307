import React, { useEffect, useState } from "react";
import eyeIcon from "./../../../assets/img/eye.svg";
import eyeSlashIcon from "./../../../assets/img/eye-slash.svg";
import "./SignupForm.scss";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { apiService } from "../../../services/apiService";
import ReCAPTCHA from "react-google-recaptcha";
import PageLoader from "../../../shared/page-loader";

interface SignupResponse {
  statusCode: null | number;
  isSuccessful: boolean;
  otp: null | string;
  message: null | string;
}
interface SignupForm {
  authService: {
    login: () => Promise<void>;
  };
}

const SignupForm: React.FC<SignupForm> = (props) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [captchaValueError, setCaptchaValueError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signupResponse, setSignupResponse] = useState<SignupResponse | null>(
    null
  );
  const [signupError, setSignupError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [formSubmission, setFormSubmission] = useState(true);
  const navigate = useNavigate();

  const validateName = () => {
    if (name.trim() === "") {
      setNameError("Please provide a valid name.");
    } else {
      setNameError("");
      validateForm();
    }
  };

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please provide a valid email.");
    } else {
      setEmailError("");
      validateForm();
    }
  };

  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (password.trim() === "") {
      setPasswordError("Please provide a password.");
    } else if (password.length < 8) {
      setPasswordError("Password should be at least 8 characters long.");
    } else if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must contain at least one uppercase letter, one special character (@$!%*?&), and one numeric character."
      );
    } else {
      setPasswordError("");
    }
  };

  const validateConfirmPassword = (value: string) => {
    if (value.trim() === "") {
      setConfirmPasswordError("Please confirm the password.");
    } else if (value !== password) {
      setConfirmPasswordError("Both passwords should match.");
    } else {
      setConfirmPasswordError("");
    }
    validateForm();
  };

  useEffect(() => {
    if (name) validateName();
    if (email) validateEmail();
    if (password) validatePassword();
    if (confirmPassword) validateConfirmPassword(confirmPassword);
  }, [name, email, password, confirmPassword]);

  const handleRecaptchaChange = (value: string | null) => {
    if (!value) {
      setCaptchaValueError("Please complete the CAPTCHA.");
      setIsFormValid(false);
    } else {
      setCaptchaValue(value);
      setCaptchaValueError("");
      validateForm(); // Call validation after updating captchaValue
    }
  };

  const validateForm = () => {
    const isCaptchaValid = captchaValue !== "";
    if (
      nameError === "" &&
      emailError === "" &&
      passwordError === "" &&
      confirmPasswordError === "" &&
      isCaptchaValid &&
      agreeToTerms &&
      name !== "" &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      captchaValueError === ""
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("userSignup", JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);
  const handleTermsChange = () => {
    setAgreeToTerms((prevAgreeToTerms) => !prevAgreeToTerms);
  };
  useEffect(() => {
    validateForm();
  }, [agreeToTerms]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    const endpoint = "Register"; // Update with your actual API endpoint
    const postData = {
      Name: name,
      Email: email,
      Password: password,
      ConfirmPassword: confirmPassword,
      Type: 2,
    };

    setLoading(true);
    try {
      setFormSubmission(false);
      setTimeout(async () => {
        try {
          const response = await apiService.postIdentity(endpoint, postData);
          setSignupResponse(response);
          setData(response);
          const id = response.objectId;
          setLoading(false);
          toast.success("Company added successfully!");
          navigate(`/signup/services?id=${id}`);
          setFormSubmission(true);
        } catch (error: any) {
          if (error.response && error.response.status === 409) {
            // Conflict error
            toast.error("Company already exists!");
          } else {
            // Handle other specific error cases
            toast.error("An error occurred while signing up.");
          }
          setFormSubmission(true);
        }
      }, 1000); // 1000 milliseconds (1 second)
    } finally {
      console.log(signupResponse);
      setLoading(false);
    }
  };

  const handleLogin = () => {
    props.authService.login();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    validateName();
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    validateEmail();
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    validatePassword();
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setConfirmPassword(value);
    validateConfirmPassword(value);
  };
  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <h1>Join us</h1>
      <p className="text-muted small">
        Already signed up?{" "}
        <a className="text-primary cursor-pointer" onClick={handleLogin}>
          Login!
        </a>
      </p>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="mb-3">
          <input
            type="text"
            placeholder="Company name"
            className={`form-control ${nameError ? "is-invalid" : ""}`}
            required
            value={name}
            onChange={handleNameChange}
            onBlur={validateName}
          />
          {nameError && <div className="invalid-feedback">{nameError}</div>}
        </div>
        <div className="mb-3">
          <input
            type="email"
            placeholder="Email"
            className={`form-control ${emailError ? "is-invalid" : ""}`}
            required
            value={email}
            onChange={handleEmailChange}
            onBlur={validateEmail}
            autoComplete="new-email"
          />
          {emailError && <div className="invalid-feedback">{emailError}</div>}
        </div>
        <div className="mb-3 password-container">
          <i
            className="eye-icon"
            onClick={() => setHidePassword(!hidePassword)}
          >
            <img src={hidePassword ? eyeSlashIcon : eyeIcon} alt="" />
          </i>
          <input
            type={hidePassword ? "password" : "text"}
            placeholder="Password"
            className={`form-control ${passwordError ? "is-invalid" : ""}`}
            required
            value={password}
            onChange={handlePasswordChange}
            onBlur={validatePassword}
            autoComplete="new-password"
          />
          {passwordError ? (
            <div className="invalid-feedback">{passwordError}</div>
          ) : (
            <div className="form-text">
              Password must contain at least one uppercase letter, one special
              character (@$!%*?&), and one numeric character.
            </div>
          )}
        </div>
        <div className="mb-3 password-container">
          <i
            className="eye-icon"
            onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
          >
            <img src={hideConfirmPassword ? eyeSlashIcon : eyeIcon} alt="" />
          </i>
          <input
            type={hideConfirmPassword ? "password" : "text"}
            placeholder="Confirm password"
            className={`form-control ${
              confirmPasswordError ? "is-invalid" : ""
            }`}
            required
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
              validateConfirmPassword(event.target.value)
            }
            autoComplete="new-password"
          />
          {confirmPasswordError && (
            <div className="invalid-feedback">{confirmPasswordError}</div>
          )}
        </div>
        <div className="mb-3">
          <ReCAPTCHA
            sitekey="6LebExcoAAAAAOvnkOfbLtKuZI6FVxWrB8cO2_OP"
            onChange={handleRecaptchaChange}
          />
        </div>
        <div className="form-check form-check-inline mb-3 mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            id="agree-to-terms"
            checked={agreeToTerms}
            onChange={handleTermsChange}
            required
          />
          <label className="form-check-label" htmlFor="agree-to-terms">
            By signing up, I agree with the
          </label>{" "}
          <a target="_blank" href="/terms-conditions">
            Terms and Conditions
          </a>
          <div className="invalid-feedback">
            Please agree to the Terms and Conditions.
          </div>
        </div>
        <div className="d-grid gap-2">
          {formSubmission ? (
            <button
              className="btn btn-primary"
              type="submit"
              disabled={!isFormValid}
            >
              Next
            </button>
          ) : (
            <button className="btn btn-primary" type="button" disabled>
              <span
                className="spinner-grow spinner-grow-sm"
                aria-hidden="true"
              ></span>
              <span className="ms-2" role="status">
                Creating company
              </span>
            </button>
          )}
        </div>
        <p className="mt-3 text-center">
          <NavLink to="/">Back to home</NavLink>
        </p>
      </form>
    </>
  );
};

export default SignupForm;
