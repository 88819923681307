import { NavLink } from "react-router-dom";
import "../EditProfile.scss";
function Aside() {
  return (
    <>
      <aside>
        <div className="list-group list-group-flush">
          <NavLink
            to="/edit-profile/basic"
            className="list-group-item list-group-item-action"
          >
            Basics
          </NavLink>

          <NavLink
            to="/edit-profile/services"
            className="list-group-item list-group-item-action"
          >
            Services
          </NavLink>
          <NavLink
            to="/edit-profile/technologies"
            className="list-group-item list-group-item-action"
          >
            Technologies
          </NavLink>
          <NavLink
            to="/edit-profile/poc"
            className="list-group-item list-group-item-action"
          >
            Contact Person
          </NavLink>
          <NavLink
            to="/edit-profile/references"
            className="list-group-item list-group-item-action"
          >
            References
          </NavLink>
          {/* <NavLink
                        to="/edit-profile/password"
                        className="list-group-item list-group-item-action"
                    >
                        Password
                    </NavLink> */}
        </div>
      </aside>
    </>
  );
}

export default Aside;
