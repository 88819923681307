import React, { useState, useEffect } from "react";
import BlogCard from "../../../shared/BlogCard";

interface BlogPost {
  id: number;
  title: string;
  description: string;
  url: string;
  thumbnail: string;
}

const BlogPost: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const popularArticles: BlogPost[] = [
      {
        id: 101,
        title:
          "Agile Methodologies and Flutter Development: A Perfect Synergy.",
        description:
          "Since its inception, Flutter, Google’s UI toolkit for building natively compiled applications for mobile, web, and desktop from a single codebase, has taken the app development world by ...",
        url: "https://itcraftapps.com/blog/agile-methodologies-and-flutter-development-a-perfect-synergy/",
        thumbnail:
          "https://itcraftapps.com/wp-content/uploads/2023/08/agile-methodologies-and-flutter-development.jpg.webp",
      },
      {
        id: 102,
        title: "How Much Does it Cost to Make An App in 2024?",
        description:
          "There seems to be no slowdown in digitisation and app development as we enter 2024. According to data.ai, users in mobile-first markets like the United States and the UK spend nearly five ...",
        url: "https://itcraftapps.com/blog/how-much-does-it-cost-to-make-an-app-in-2022/",
        thumbnail:
          "https://itcraftapps.com/wp-content/uploads/2023/03/Blog_thumbnail_how_much_cost_app_2024.png.webp",
      },
      {
        id: 103,
        title: "What Is Software Outsourcing and How Much Does it Cost?",
        description:
          "Are you looking for a software house to create your app? Nowadays, thanks to outsourcing services, the whole world is at your disposal. You can opt for locally-based services or choose ...",
        url: "https://itcraftapps.com/blog/what-is-software-outsourcing-and-how-much-does-it-cost/",
        thumbnail:
          "https://itcraftapps.com/wp-content/uploads/2020/03/Group-10221-1.png.webp",
      },
    ];

    setBlogPosts(popularArticles);
    setLoading(false);
  }, []);

  return (
    <section className="near-you">
      <h3 className="mt-5 mb-4">My top picks for you</h3>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="row row-cols-1 row-cols-md-3 g-3">
            {blogPosts.length > 0 ? (
              blogPosts.map((blogData) => (
                <div key={blogData.id} className="col">
                  <BlogCard blogData={blogData} />
                </div>
              ))
            ) : (
              <p>No Popular Articles</p>
            )}
          </div>
          {/* <div className="d-grid gap-2 d-flex justify-content-center mt-5">
            <button className="btn btn-primary" type="button">
              Explore More
            </button>
          </div> */}
        </>
      )}
    </section>
  );
};

export default BlogPost;
