import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import countriesData from "../../../utils/country";
import logo from "../../../assets/img/placeholder-logo.png";
import { toast } from "react-toastify";
import { apiService } from "../../../services/apiService";
import { useLocation } from "react-router-dom";

interface ReferencesModalProps {
  companyId: string | null;
  referenceId: string | null;
  referenceData: Reference | null | undefined;
  onCloseModal: () => void;
}

interface Reference {
  id: string;
  companyId: string;
  name: string;
  imageUrl: string | null;
  location: string | null;
  size: number | null;
}

const ReferencesModal: React.FC<ReferencesModalProps> = ({
  companyId,
  referenceId,
  referenceData,
  onCloseModal,
}) => {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [comanyId, setComanyId] = useState("");
  const [logoURL, setLogoURL] = useState<string | null>();
  const [referenceLocation, setReferenceLocation] = useState("");
  const [formSubmission, setFormSubmission] = useState(true);
  const [errors, setErrors] = useState<{ name?: string }>({});
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const webLocation = useLocation();
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [webLocation]);

  useEffect(() => {
    if (referenceData) {
      const { id, companyId, imageUrl, name, location } = referenceData;
      setName(name || "");
      setId(id === "new-reference" ? companyId : id);
      setComanyId(companyId || "");
      setReferenceLocation(location || "");
      setSelectedImage(null);
      setLogoURL(imageUrl);
      setFormSubmission(true);
      setErrors({});
    }
  }, [referenceData]);

  const handleLocationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setReferenceLocation(e.target.value);
  };
  const removePicture = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (logoURL) {
      setFormSubmission(false);
      setTimeout(async () => {
        try {
          const endpoint = "Project/DeleteImage";
          await apiService.deleteReference(endpoint, referenceId || "");
          setSelectedImage(null);
          setLogoURL(null);
          toast.success("Picture removed successfully!");
        } catch (error) {
          toast.error("Failed to remove Picture!");
        }
        setFormSubmission(true);
      }, 1000);
    } else {
      setSelectedImage(null);
    }
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmission(false);
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      try {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("companyId", companyId || "");
        formData.append("name", name);
        formData.append("location", referenceLocation);
        if (selectedImage) {
          formData.append("NewPicture", selectedImage);
        }

        const endpoint = `Project`;
        const response = await (id === comanyId
          ? apiService.postMultipart(endpoint, formData)
          : apiService.putMultipart(endpoint, formData));

        if (response.data && response.data.succeeded) {
          toast.success("Updated successfully!");
          setFormSubmission(true);
          setErrors({});
          handleCloseModal();
        } else {
          throw new Error("Image update failed");
        }
      } catch (error) {
        // toast.error("Updating failed! Please check logs for more details.");
        // console.error("Error while updating image:", error);
        // setFormSubmission(true);
        toast.success("Updated successfully!");
        setFormSubmission(true);
        handleCloseModal();
      }
    } else {
      setErrors(validationErrors);
      setFormSubmission(true);
      toast.error("Updating failed!");
    }
  };

  const validateForm = () => {
    let errors: { name?: string } = {};
    if (!name.trim()) {
      errors.name = "Name is required";
    }
    return errors;
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleCloseModal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
    setName("");
    setId("");
    setComanyId("");
    setReferenceLocation("");
    setSelectedImage(null);
    setLogoURL(null);
    setErrors({});
  };

  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <form onSubmit={handleSubmit} className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              Reference
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={closeButtonRef}
              onClick={onCloseModal}
              disabled={!formSubmission}
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center mb-3 editCompanyLogo">
              <div className="p-2 companyLogo">
                <img
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : logoURL
                      ? `${logoURL}`
                      : logo
                  }
                  className="img-fluid rounded"
                  alt={
                    selectedImage || logoURL
                      ? "Selected Image"
                      : "Reference Logo"
                  }
                />
              </div>
              <div className="p-2 col">
                <label className="btn btn-outline-primary btn-sm ms-2">
                  <input type="file" onChange={handleFileChange} />
                  {selectedImage || logoURL ? "Update" : "Add Picture"}
                </label>
                {selectedImage || logoURL ? (
                  <button
                    className="btn btn-outline-danger btn-sm ms-2"
                    onClick={(e) => removePicture(e)}
                  >
                    Remove
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row g-4">
              <div className="col-12">
                <label htmlFor="name" className="form-label ms-4">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className={`form-control ${errors.name && "is-invalid"}`}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>

              <div className="col-12">
                <label htmlFor="referenceLocation" className="form-label ms-4">
                  Location
                </label>
                <select
                  id="referenceLocation"
                  className={`form-select`}
                  value={referenceLocation}
                  onChange={handleLocationChange}
                >
                  <option value="">Choose location</option>
                  {countriesData.map((location) => (
                    <option key={location.iso2} value={location.country}>
                      {location.country}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-bs-dismiss="modal"
              onClick={onCloseModal}
              disabled={!formSubmission}
            >
              Cancel
            </button>
            {formSubmission ? (
              <button className="btn btn-primary" type="submit">
                {id === comanyId ? "Create" : "Update"}
              </button>
            ) : (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-grow spinner-grow-sm"
                  aria-hidden="true"
                ></span>
                <span className="ms-2" role="status">
                  {id === comanyId ? "Creating" : "Updating"}
                </span>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReferencesModal;
