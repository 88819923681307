import React, { useEffect, useState } from "react";
import { apiService } from "../../../services/apiService";
import "../EditProfile.scss";

interface SecurityFormProps {}

const Security: React.FC<SecurityFormProps> = () => {
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState<null | "success">(
    null
  );
  const [errors, setErrors] = useState<{
    password?: string;
    newPassword?: string;
    confirmPassword?: string;
  }>({});
  const id = "afasdfa-adfasfd-afdsasfd";
  const endpoint = `Company/${id}`;

  useEffect(() => {
    apiService
      .get(endpoint)
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, proceed with submission
      // Log form data to console
      console.log("Form Data:", {
        password,
        newPassword,
        confirmPassword,
      });

      // Perform form submission logic here (e.g., send data to a server)

      // Simulating a successful submission
      // Replace the setTimeout function with your actual submission logic
      setTimeout(() => {
        // Show success message
        setSubmissionStatus("success");
      }, 2000);
    } else {
      // Form is invalid, display errors
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    let errors: {
      password?: string;
      newPassword?: string;
      confirmPassword?: string;
    } = {};

    // Validate password field
    if (!password.trim()) {
      errors.password = "Password is required";
    } else if (!isStrongPassword(password)) {
      errors.password = "Password must be strong enough";
    }

    // Validate new password field
    if (!newPassword.trim()) {
      errors.newPassword = "New password is required";
    } else if (newPassword.length < 6) {
      errors.newPassword = "New password must be at least 6 characters long";
    }

    // Validate confirm password field
    if (!confirmPassword.trim()) {
      errors.confirmPassword = "Confirm Password is required";
    } else if (confirmPassword !== newPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  const isStrongPassword = (password: string) => {
    // Define your password strength rules here
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row g-4">
          <div className="col-md-6">
            <label htmlFor="currentPassword" className="form-label ms-4">
              Current Password
            </label>
            <input
              type="password"
              id="currentPassword"
              className={`form-control ${errors.password && "is-invalid"}`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Current Password"
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password}</div>
            )}
          </div>
        </div>
        <div className="row g-4 mt-0">
          <div className="col-md-6">
            <label htmlFor="newPassword" className="form-label ms-4">
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              className={`form-control ${errors.newPassword && "is-invalid"}`}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
            />
            {errors.newPassword && (
              <div className="invalid-feedback">{errors.newPassword}</div>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="confirmPassword" className="form-label ms-4">
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className={`form-control ${
                errors.confirmPassword && "is-invalid"
              }`}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
            />
            {errors.confirmPassword && (
              <div className="invalid-feedback">{errors.confirmPassword}</div>
            )}
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-5">
          <button className="btn btn-primary" type="submit">
            Save changes
          </button>
        </div>
      </form>
    </>
  );
};

export default Security;
