import countriesData from "../../../../src/utils/country";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { apiService } from "../../../services/apiService";
import Company from "../../../Models/Company";
import CompanySize from "../../../Models/CompanySize";
import IndustryType from "../../../Models/IndustryType";
import "../EditProfile.scss";
import CompanyImage from "./CompanyImage";
import { useLocation } from "react-router-dom";
import PageLoader from "../../../shared/page-loader";
interface BasicsFormProps {
  companyId: null | string;
}
const enumLength = Object.keys(CompanySize).length / 2;
const industryTypeLength = Object.keys(IndustryType).length / 2;
const Basics: React.FC<BasicsFormProps> = (companyId) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [formSubmission, setFormSubmission] = useState(true);
  const [imageUrl, setImageUrl] = useState<string | null>();
  const [userCompany, setUserCompany] = useState<Company | null>(null);
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [companyStrength, setCompanyStrength] = useState("");
  const [headOffice, setHeadOffice] = useState("");
  const [foundedIn, setFoundedIn] = useState<Date | null>(null);
  const [foundedBy, setFoundedBy] = useState("");
  const [sector, setSector] = useState("");
  const [errors, setErrors] = useState<{
    name?: string;
    description?: string;
    website?: string;
    companyStrength?: string;
    headOffice?: string;
    foundedIn?: string;
    foundedBy?: string;
    sector?: string;
  }>({});

  const webLocation = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [webLocation]);

  const handleCompanyStrengthChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCompanyStrength(e.target.value);
  };

  const handleSectoreChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSector(e.target.value);
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // console.log(e.target.value);
    setHeadOffice(e.target.value);
  };

  const id = companyId.companyId;
  useEffect(() => {
    if (id !== "" || null) {
      const endpoint = `Company/${id}`;
      apiService
        .get(endpoint)
        .then((response) => {
          setUserCompany(response);
          setImageUrl(response.profilePictureUrl);
          setName(response.name);
          setDescription(response.description);
          setCompanyStrength(response.size);
          setWebsite(response.website);
          setHeadOffice(response.location);
          setFoundedIn(response.foundedIn);
          setFoundedBy(response.foundedBy);
          setSector(response.industryType);
          setLoading(false);
          setPageLoading(false);
        })
        .catch((error) => {
          setTimeout(() => {
            setPageLoading(false);
          }, 1000);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [companyId]);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      const formData = {
        id: id,
        requestUserId: id,
        name: name ? name : "",
        size: parseInt(companyStrength || "0"),
        website: website ? website : "",
        description: description ? description : "",
        location: headOffice ? headOffice : "",
        foundedIn: foundedIn ? foundedIn : null,
        foundedBy: foundedBy ? foundedBy : "",
        industryType: sector ? sector : null,
      };
      try {
        setFormSubmission(false);
        setTimeout(async () => {
          const endpoint = `Company`;
          const response = await apiService.put(endpoint, formData);
          setFormSubmission(true);
          setErrors({}); // Clear the validation errors after successful submission
          toast.success("Updated successfully!");
        }, 1000);
      } catch (error) {
        toast.error("Updating failed!");
        setFormSubmission(true);
      }
    } else {
      setErrors(validationErrors);
      setFormSubmission(true);
      toast.error("Updating failed!");
    }
  };

  useEffect(() => {
    validateForm();
  }, [name]);

  const validateForm = () => {
    let errors: {
      name?: string;
    } = {};

    // Validate name field
    if (!name.trim()) {
      errors.name = "Name is required";
    }

    return errors;
  };

  const companyLogo = imageUrl
    ? imageUrl.includes("Uploads")
      ? `${imageUrl}`
      : imageUrl
    : null;

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <form onSubmit={handleSubmit} className="row g-4">
        <div className="col-12">
          {!loading && <CompanyImage imageUrl={companyLogo} companyId={id} />}
        </div>

        <div className="col-md-6">
          <label htmlFor="name" className="form-label ms-4">
            Name
          </label>
          <input
            type="text"
            id="name"
            className={`form-control ${errors.name && "is-invalid"}`}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
        </div>

        <div className="col-md-6">
          <label htmlFor="website" className="form-label ms-4">
            Website
          </label>
          <input
            type="website"
            id="website"
            className={`form-control`}
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            placeholder="Website"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="foundedin" className="form-label ms-4">
            Founded in
          </label>
          <DatePicker
            id="foundedin"
            selected={foundedIn} // Use the state variable for the selected date
            onChange={(date: Date) => setFoundedIn(date)} // Update the state with the selected date
            dateFormat="yyyy-MM-dd" // Format the date as required by the backend API
            className={`form-control`}
            placeholderText="Founded in"
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="foundedBy" className="form-label ms-4">
            Founded by
          </label>
          <input
            type="text"
            id="foundedBy"
            className={`form-control`}
            value={foundedBy}
            onChange={(e) => setFoundedBy(e.target.value)}
            placeholder="Founded by"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="sector" className="form-label ms-4">
            Sector
          </label>
          <select
            id="sector"
            className={`form-select`}
            value={sector}
            onChange={handleSectoreChange}
          >
            {Array.from({ length: industryTypeLength }, (_, index) => {
              const key = Object.keys(IndustryType)[index];
              const value = IndustryType[key as keyof typeof IndustryType];
              return (
                <option key={index} value={key}>
                  {value}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-md-6">
          <label htmlFor="headOffice" className="form-label ms-4">
            Location
          </label>
          <select
            id="headOffice"
            className={`form-select`}
            value={headOffice}
            onChange={handleLocationChange}
          >
            <option value="">Choose location</option>
            {countriesData.map((location) => (
              <option key={location.iso2} value={location.country}>
                {location.country}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label htmlFor="companyStrength" className="form-label ms-4">
            Company Strength
          </label>
          <select
            id="companyStrength"
            className={`form-select`}
            value={companyStrength}
            onChange={handleCompanyStrengthChange}
          >
            {Array.from({ length: enumLength }, (_, index) => {
              const key = Object.keys(CompanySize)[index];
              const value = CompanySize[key as keyof typeof CompanySize];
              return (
                <option key={index} value={key}>
                  {value}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6">
          <label htmlFor="description" className="form-label ms-4">
            Description
          </label>
          <textarea
            id="description"
            className={`form-control`}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            maxLength={500}
            rows={5}
          ></textarea>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-5">
          {formSubmission ? (
            <button className="btn btn-primary" type="submit">
              Save changes
            </button>
          ) : (
            <button className="btn btn-primary" type="button" disabled>
              <span
                className="spinner-grow spinner-grow-sm"
                aria-hidden="true"
              ></span>
              <span className="ms-2" role="status">
                Saving
              </span>
            </button>
          )}
        </div>
      </form>
    </>
  );
};
export default Basics;
