import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeadingBanner from "../../components/Banner/HeadingBanner";
import PageLoader from "../../shared/page-loader";
import "./Other.scss";
function PrivacyPolicy() {
  const [pageLoading, setPageLoading] = useState(true);
  const webLocation = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [webLocation]);

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      {/* <HeadingBanner
        contactTitle={contactType ? "Advertise with" : "Contact"}
      /> */}
      <HeadingBanner title="Privacy policy" updated="22/11/2023" />
      <main className="OtherPage container py-3">
        <h3>Introduction</h3>
        <p>
          Welcome to AskAlva.com, operated by Quantum CPH A/S. We are committed
          to protecting your privacy and ensuring the security of your personal
          information. This privacy policy explains how we collect, use, and
          safeguard your information when you visit our website or use our
          services.
        </p>
        <h3>Information we collect</h3>
        <h4>Personal information</h4>
        <p>
          <b>Registration data:</b> When you create an account, we may collect
          information such as your name, email address, and other relevant
          details.
        </p>
        <p>
          <b>Profile information:</b> Users may choose to provide additional
          information on their profiles, including but not limited to location,
          contact details, and other preferences.
        </p>
        <p>
          <b>User-generated content:</b> Any content you submit, including
          listings, reviews, and comments, may be collected and stored.
        </p>
        <h4>Usage data</h4>
        <p>
          We currently do not collect information about your interactions with
          our website, including your IP address, browser type, and pages
          visited. This information is collected using cookies and similar
          technologies.
        </p>
        <h4>Cookies and tracking technologies</h4>
        <p>
          We use cookies to enhance your experience on our website. You can
          manage your cookie preferences through your browser settings.
        </p>
        <h4>How we use your information</h4>
        <p>We use the collected information for the following purposes:</p>
        <ul>
          <li>To provide and maintain our services.</li>
          <li>To personalize your experience on our website.</li>
          <li>
            To communicate with you, including responding to inquiries and
            providing updates.
          </li>
          <li>To improve our website and services.</li>
          <li>
            To prevent fraudulent activities and ensure compliance with our
            terms of service.
          </li>
        </ul>
        <h4>Data sharing and disclosure</h4>
        <p>We may share your information in the following circumstances:</p>
        <ul>
          <li>With your consent.</li>
          <li>To comply with legal obligations.</li>
          <li>In response to lawful requests from public authorities.</li>
        </ul>
        <h4>Security</h4>
        <p>
          We take reasonable measures to protect your information from
          unauthorized access and disclosure. However, no method of transmission
          over the internet or electronic storage is completely secure.
        </p>
        <h4>Your rights</h4>
        <p>You have the right to:</p>
        <ul>
          <li>Access, correct, or delete your personal information.</li>
          <li>Withdraw consent for processing your data.</li>
          <li>Lodge a complaint with a supervisory authority.</li>
        </ul>
        <h4>Changes to this privacy policy</h4>
        <p>
          We may update this privacy policy periodically to reflect changes in
          our practices or legal requirements. The date of the last update will
          be indicated at the top of the page.
        </p>
        <h4>Contact us</h4>
        <p>
          If you have any questions about this privacy policy, please contact us
          at <a href="mailto:co@quantumcph.com">co@quantumcph.com</a>
        </p>
      </main>
    </>
  );
}

export default PrivacyPolicy;
