import React, { useEffect, useState } from "react";
import { apiService } from "../../../services/apiService";
import Company from "../../../Models/Company";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "../EditProfile.scss";
import ReferencesModal from "./ReferencesModal";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/placeholder-logo.png";
import ReferencesDelete from "./ReferencesDelete";
import PageLoader from "../../../shared/page-loader";

interface Reference {
  id: string;
  companyId: string;
  name: string;
  imageUrl: string | null;
  location: string | null;
  size: number | null;
}

interface ReferencesFormProps {
  companyId: null | string;
}
const References: React.FC<ReferencesFormProps> = (companyId) => {
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [listUpdated, setListUpdated] = useState(false);
  const [references, setReferences] = useState<Reference[]>([]);
  const [referenceEdit, setReferenceEdit] = useState<string>("");
  const [referenceDelete, setReferenceDelete] = useState<string>("");
  const [editReferenceData, setEditReferenceData] = useState<Reference | null>(
    null
  );

  const webLocation = useLocation();

  const id = companyId.companyId;
  const referenceEndPoint = `Project/GetCompanyProjects/${id}`;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [webLocation]);

  useEffect(() => {
    updateReferenceList();
  }, [companyId]);

  const updateReferenceList = () => {
    if (id !== "" || null) {
      apiService
        .get(referenceEndPoint)
        .then((response) => {
          setReferences(response);
          setLoading(false);
          setPageLoading(false);
        })
        .catch((error) => {
          setTimeout(() => {
            setPageLoading(false);
          }, 1000);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const findReferenceData = (id: string) => {
    const foundReference = references.find((ref) => ref.id === id);
    if (foundReference) {
      setEditReferenceData(foundReference);
    }
  };
  const handleAddReference = () => {
    setReferenceEdit("new-reference");
    // Pass default values when adding a new reference
    setEditReferenceData({
      id: id || "",
      companyId: id || "", // Adjust companyId based on your logic
      name: "",
      imageUrl: null,
      location: null,
      size: null,
    });
  };
  const handleModalClose = () => {
    updateReferenceList();
  };

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      {references && references.length > 0 ? (
        <>
          <div className="d-flex justify-content-between mb-3">
            <h6 className="mb-0 mt-3">References</h6>
            {/* <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => {
                setReferenceEdit("new-reference");
                findReferenceData("new-reference");
              }}
            >
              Add Reference
            </button> */}

            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={handleAddReference} // Call function when adding a reference
            >
              Add Reference
            </button>
          </div>
          {references.map((referenceData) => (
            <div
              key={referenceData.id}
              className="d-flex text-body-secondary pt-3 "
            >
              <div className=" flex-shrink-0 pe-2">
                <div className="company-logo reference-logo">
                  <img
                    src={
                      referenceData.imageUrl
                        ? `${referenceData.imageUrl}`
                        : logo
                    }
                    alt={referenceData.name}
                  />
                </div>
              </div>
              <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                <div className="d-flex justify-content-between">
                  <strong className="text-gray-dark">
                    {referenceData.name}
                  </strong>
                  <span>
                    <button
                      type="button"
                      className="btn btn-link btn-sm text-danger me-2 p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteReferenceBackdrop"
                      onClick={() => setReferenceDelete(referenceData.id)}
                    >
                      Remove
                    </button>
                    <button
                      type="button"
                      className="btn btn-link btn-sm text-primary p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      onClick={() => {
                        setReferenceEdit(referenceData.id);
                        findReferenceData(referenceData.id);
                      }}
                    >
                      Edit
                    </button>
                  </span>
                </div>
                <span className="d-block">{referenceData.location}</span>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="px-3 pt-5 text-center">
          <h3>References</h3>
          <p className="lead">No references found.</p>
          <p className="mb-5">
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Add Reference
            </button>
          </p>
        </div>
      )}

      {id ? (
        <>
          <ReferencesModal
            companyId={id}
            referenceId={referenceEdit}
            referenceData={editReferenceData}
            onCloseModal={handleModalClose}
          />
          <ReferencesDelete
            companyId={id}
            referenceId={referenceDelete}
            onCloseModal={handleModalClose}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default References;
