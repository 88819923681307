import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeadingBanner from "../../components/Banner/HeadingBanner";
import PageLoader from "../../shared/page-loader";
import "./Other.scss";
function TermsConditions() {
  const [pageLoading, setPageLoading] = useState(true);
  const webLocation = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [webLocation]);

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <HeadingBanner title="Terms and conditions" updated="22/11/2023" />
      <main className="OtherPage container py-3">
        <h3>Acceptance of terms</h3>
        <p>
          By accessing or using askAlva.com, you agree to comply with and be
          bound by these terms and conditions. If you do not agree with any part
          of these terms, please do not use our services.
        </p>
        <h4>Changes to terms</h4>
        <p>
          We reserve the right to modify, update, or replace these terms and
          conditions at any time. The date of the last update will be indicated
          at the top of this page. It is your responsibility to check this page
          periodically for changes.
        </p>
        <h4>Use of the website</h4>
        <p>
          <b>User account:</b> To access certain features of the website, you
          may be required to create an account. You are responsible for
          maintaining the confidentiality of your account information and for
          all activities that occur under your account.
        </p>
        <p>
          <b>Prohibited activities:</b> You agree not to engage in any
          prohibited activities, including but not limited to:
        </p>
        <ul>
          <li>Violating any applicable laws or regulations.</li>
          <li>Interfering with the proper functioning of the website.</li>
          <li>
            Attempting to gain unauthorized access to any part of the website.
          </li>
        </ul>
        <h4>User-generated content</h4>
        <p>
          By submitting content to the website, you grant a worldwide,
          non-exclusive, royalty-free license to use, reproduce, modify, adapt,
          publish, translate, create derivative works from, distribute, and
          display such content.
        </p>
        <h4>Intellectual property</h4>
        <p>
          The website and its original content, features, and functionality are
          owned by Quantum CPH A/S and are protected by international copyright,
          trademark, patent, trade secret, and other intellectual property or
          proprietary rights laws.
        </p>
        <h4>Disclaimer</h4>
        <p>
          The Website is provided on an "as-is" and "as-available" basis. We
          make no representations or warranties of any kind, express or implied,
          regarding the accuracy, reliability, or availability of the website.
        </p>
        <h4>Limitation of liability</h4>
        <p>
          To the fullest extent permitted by applicable law, Quantum CPH A/S or
          any of its subsidiaries, products or partners, shall not be liable for
          any indirect, incidental, special, consequential, or punitive damages,
          or any loss of profits or revenues.
        </p>
        <h4>Governing law</h4>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of Denmark, without regard to its conflict of law
          provisions.
        </p>
        <h4>Contact us</h4>
        <p>
          If you have any questions about this Privacy policy, please contact us
          at <a href="mailto:co@quantumcph.com">co@quantumcph.com</a>
        </p>
      </main>
    </>
  );
}

export default TermsConditions;
