import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "./../../assets/img/placeholder-logo.png";
import verifiedBadge from "./../../assets/img/verified.svg";
import "./Banner.scss";

interface BannerProps {
  companyLogo?: string | null;
  companyName?: string | null;
  companyLocation?: string | null;
  companyClaimable?: boolean | null;
}

const CompanyBanner: React.FC<BannerProps> = (props) => {
  const videoPath = `./../../assets/videos/company-banner.mp4`;
  const logoUrl = props.companyLogo ? props.companyLogo : logo;
  const isClaimable = props.companyClaimable;

  const [scroll, setScroll] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollCheck = window.scrollY >= 70;
      setScroll(scrollCheck);
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`company-banner ${scroll ? "scrolled" : ""}`}>
      <video autoPlay loop muted className="banner-video">
        <source src={videoPath} type="video/mp4" />
      </video>
      <div className="container">
        <div className="logo p-2">
          <img src={logoUrl} alt="" />
        </div>
        <div>
          <div
            className={`company-info ${isClaimable ? "claimable" : "verified"}`}
          >
            <h3 className="name">
              <span>{props.companyName}</span>
              {isClaimable ? (
                ""
              ) : (
                <img
                  className="verifiedBadge text-success"
                  src={verifiedBadge}
                  alt="Verified"
                />
              )}
            </h3>
            {props.companyLocation && (
              <div>
                <i className="info"></i>
                <p className="location mb-0">{props.companyLocation}</p>
              </div>
            )}
            {isClaimable ? (
              <button
                type="button"
                className="btn btn-primary btn-sm mt-2"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                Claim company
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyBanner;
