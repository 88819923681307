import React, { useState, useEffect } from "react";
import { apiService } from "../../services/apiService";
import { useSearchParams, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import "./Contact.scss";
import HeadingBanner from "../../components/Banner/HeadingBanner";
import PageLoader from "../../shared/page-loader";

const Contact: React.FC = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const contactType = searchParams.get("contactType") || "";
  const id = "3d5c69c3-266e-4d48-97bb-08dc47ff6440";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState<null | "success">(
    null
  );
  const [formSubmission, setFormSubmission] = useState(true);
  const [errors, setErrors] = useState<{
    name?: string;
    email?: string;
    subject?: string;
    phone?: string;
    message?: string;
    captchaValue?: string;
  }>({});

  useEffect(() => {
    setSubject(contactType ? "For advertising" : "");
  }, [contactType]);
  useEffect(() => {
    const isFormValid =
      name.trim() &&
      email.trim() &&
      /\S+@\S+\.\S+/.test(email) &&
      subject.trim() &&
      phone.trim() &&
      message.trim() &&
      captchaValue.trim();

    setIsFormValid(Boolean(isFormValid));
  }, [name, email, subject, phone, message, captchaValue]);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateForm();
    const companyId = id;
    console.log(companyId);

    if (Object.keys(validationErrors).length === 0) {
      const formData = {
        Name: name,
        CompanyId: companyId,
        Email: email,
        PhoneNumber: phone,
        ReasonOfMessage: subject,
        Details: message,
      };
      // setTimeout(() => {
      //   setName("");
      //   setEmail("");
      //   setMessage("");
      //   setSubject("");
      //   setSubmissionStatus("success");
      // }, 2000);

      try {
        setFormSubmission(false);
        setTimeout(async () => {
          // Send POST request to the contact API
          const endpoint = `Contact`;
          await apiService
            .post(endpoint, formData)
            .then((response) => {
              if (response) {
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
                setSubject("");
                setSubmissionStatus("success");
              } else {
                // Handle unsuccessful submission if needed
                //setSubmissionStatus("success");
              }
            })
            .catch((error) => {
              console.error(error);
            });

          setFormSubmission(true);
          toast.success("Message submitted!");
        }, 1000); // 1000 milliseconds (1 second)
      } catch (error) {
        // Handle error if needed
        //setSubmissionStatus("success");
        toast.error("Message failed!");
        setFormSubmission(true);
      }
    } else {
      // Form is invalid, display errors
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    let errors: {
      name?: string;
      email?: string;
      subject?: string;
      phone?: string;
      message?: string;
      captchaValue?: string;
    } = {};

    // Validate name field
    if (!name.trim()) {
      errors.name = "Name is required";
    }

    // Validate email field
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format";
    }

    // Validate subject field
    if (!subject.trim()) {
      errors.subject = "Subject is required";
    }

    // Validate phone field
    if (!phone.trim()) {
      errors.phone = "Phone is required";
    }

    // Validate message field
    if (!message.trim()) {
      errors.message = "Message is required";
    }

    // Validate captcha field
    if (!captchaValue.trim()) {
      errors.captchaValue = "Captcha is required";
    }

    return errors;
  };

  const handleRecaptchaChange = (value: string | null) => {
    if (value === "" || value === null) {
      setCaptchaValue("");
    } else {
      setCaptchaValue(value);
    }
  };
  const webLocation = useLocation();
  useEffect(() => {
    setPageLoading(true);
    setSubmissionStatus(null);
    window.scrollTo(0, 0);

    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [webLocation]);

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <HeadingBanner
        contactTitle={contactType ? "Advertise with" : "Contact"}
      />
      <div className="container py-3">
        <form className="contact-page" onSubmit={handleSubmit}>
          {submissionStatus === "success" ? (
            <>
              <p className="text-success">Message successfully submitted</p>
              <p>
                Thank you for the message. We will get in touch with you as soon
                as possible.
              </p>
            </>
          ) : (
            <>
              <div className="content">
                <div className="mb-3">
                  <input
                    type="text"
                    id="name"
                    className={`form-control ${errors.name && "is-invalid"}`}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    id="email"
                    className={`form-control ${errors.email && "is-invalid"}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    id="phone"
                    className={`form-control ${errors.phone && "is-invalid"}`}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone number with country code"
                  />
                  {errors.phone && (
                    <div className="invalid-feedback">{errors.phone}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    id="subject"
                    className={`form-control ${errors.subject && "is-invalid"}`}
                    value={contactType ? "For advertising" : subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Subject"
                    disabled={contactType ? true : false}
                  />
                  {errors.subject && (
                    <div className="invalid-feedback">{errors.subject}</div>
                  )}
                </div>
                <div className="mb-3">
                  <textarea
                    id="message"
                    className={`form-control ${errors.message && "is-invalid"}`}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Detailed Message (500 Words Limit)"
                    maxLength={500}
                    rows={5}
                  ></textarea>
                  {errors.message && (
                    <div className="invalid-feedback">{errors.message}</div>
                  )}
                </div>
                <div className="mb-3">
                  <div className={`${errors.captchaValue && "is-invalid"}`}>
                    <ReCAPTCHA
                      sitekey="6LebExcoAAAAAOvnkOfbLtKuZI6FVxWrB8cO2_OP"
                      onChange={handleRecaptchaChange}
                    />
                  </div>
                  {errors.captchaValue && (
                    <div className="invalid-feedback">
                      {errors.captchaValue}
                    </div>
                  )}
                </div>
                <div className="d-grid gap-2">
                  {formSubmission ? (
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={!isFormValid}
                    >
                      Submit
                    </button>
                  ) : (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        aria-hidden="true"
                      ></span>
                      <span className="ms-2" role="status">
                        Submitting
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default Contact;
