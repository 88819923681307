import React, { createContext, useState, useContext } from "react";

interface ImageContextProps {
  image: string;
  setImage: (newImage: string) => void;
}

const ImageContext = createContext<ImageContextProps | undefined>(undefined);

export const useImage = () => {
  const context = useContext(ImageContext);
  if (!context) {
    throw new Error("useImage must be used within an ImageProvider");
  }
  return context;
};

export const ImageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [image, setImage] = useState<string>("");
  return (
    <ImageContext.Provider value={{ image, setImage }}>
      {children}
    </ImageContext.Provider>
  );
};
