import React, { useState, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { apiService } from "../../services/apiService";
import CompaniesCard from "../../shared/CompaniesCard";
import Filters from "../../shared/Filters/Filters";
import CompanyResponse from "../../Models/CompanyResponse";
import SearchBanner from "../../components/Banner/SearchBanner";
import PageLoader from "../../shared/page-loader";

interface CompanyData {
  id: string;
  name: string;
  rating: number | null;
  profilePictureUrl: string | null;
  location: string | null;
  size: number | null;
  companyReviewes: any[]; // Update this type accordingly
}

const Search: React.FC = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [state, setState] = useState<CompanyResponse>({
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [moreCompanies, setMoreCompanies] = useState(true);

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const searchKey = searchParams.get("searchkey") || "";

  useEffect(() => {
    // Load company data based on search key
    // loadCompanyData(searchKey);
  }, [searchKey, pageNumber]);
  const webLocation = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [webLocation]);
  // useEffect(() => {
  //   const searchKey = searchParams.get("searchkey") || "";
  //   loadCompanyData(searchKey);
  // }, [location]);

  useEffect(() => {
    // Load company data based on search key and URL parameters
    const queryParams = searchParams.toString(); // Get all URL parameters
    if (pageNumber == 1) {
      loadCompanyData(searchKey, queryParams);
    }
    console.log("pageNumber");
    console.log(pageNumber);
  }, [searchKey, pageNumber, location]);

  const loadCompanyData = (searchKey: string, queryParams: string) => {
    setIsLoading(true);
    const endpoint = `Company?PageNumber=${pageNumber}&PageSize=8&OrderByColumn=name&OrderBy=ASC&${queryParams}`;
    apiService
      .get(endpoint)
      .then((response) => {
        setState({
          ...response,
          items: [...response.items],
        });

        setPageNumber(response.pageNumber);
        setIsLoading(false);
        setPageLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setPageLoading(false);
        }, 1000);
        setIsLoading(false);
      });
  };

  const handleLoadMore = () => {
    const nextPage = pageNumber + 1;

    const queryParams = searchParams.toString();
    const endpoint = `Company?PageNumber=${nextPage}&PageSize=8&OrderByColumn=name&OrderBy=ASC&${queryParams}`;

    apiService
      .get(endpoint)
      .then((response) => {
        setMoreCompanies(false);
        setTimeout(() => {
          setState((prevState) => ({
            ...response,
            items: [...prevState.items, ...response.items], // Concatenate new items with existing ones
          }));

          setPageNumber(nextPage);

          setMoreCompanies(true);
        }, 1000); // 1000 milliseconds (1 second)
      })
      .catch((error) => {
        console.error(error);
        setMoreCompanies(true);
      });
  };
  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <SearchBanner />
      <Filters />
      <main className="container">
        <section className="py-6">
          <h2 className="text-center sectionHeading">
            Getting the Most from Your Search
            <small>
              We have got the companies more relevant to your search!
            </small>
          </h2>

          <div className="row row-cols-1 row-cols-sm-3 row-cols-md-3 row-cols-lg-4 g-3">
            {state.items.length > 0 ? (
              state.items.map((companyData) => (
                <div key={companyData.id} className="col">
                  <CompaniesCard companyData={companyData} />
                </div>
              ))
            ) : (
              <p>No companies found</p>
            )}
          </div>

          {isLoading && <p>Loading...</p>}

          {!isLoading && state.hasNextPage && (
            <div className="d-grid gap-2 d-flex justify-content-center mt-5">
              {moreCompanies ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleLoadMore}
                >
                  Explore More
                </button>
              ) : (
                <button className="btn btn-primary" type="button" disabled>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="ms-2" role="status">
                    Loading
                  </span>
                </button>
              )}
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default Search;
