import "./Home.scss";
import SearchBanner from "../../components/Banner/SearchBanner";
import CompaniesList from "./Components/CompaniesList";
import FeaturedCompanies from "./Components/FeaturedCompanies";
import BlogPost from "./Components/BlogPosts";
import bannerAd from "./../../assets/img/place-ad-banner05.png";
import alvaAds from "./../../assets/img/place-ad-banner06.png";
import { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
const Home = () => {
  const webLocation = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [webLocation]);
  return (
    <>
      <SearchBanner />
      <section className="featured">
        <FeaturedCompanies />
      </section>
      <main className="container">
        <div className="text-center pt-5">
          <a
            href="https://quantumcph.com/"
            target="_blank"
            className="d-inline-block"
          >
            <img src={bannerAd} alt="" className="img-fluid" />
          </a>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-lg-9">
                <h3 className="mt-5 mb-4 community-title">
                  Say hi to our community
                  <NavLink to="/companies" className="nav-link">
                    See all
                  </NavLink>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <CompaniesList />
            <BlogPost />
          </div>
          <div className="col-lg-3 mt-3 mt-lg-0">
            <a href="mailto:info@askalva.com" className="d-inline-block">
              <img src={alvaAds} alt="" className="img-fluid" />
            </a>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
