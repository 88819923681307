import { Link } from "react-router-dom";

function BlogCard({ blogData }: any) {
  const { id, title, description, url, thumbnail } = blogData;

  return (
    <div className="card article-card h-100">
      <a href={url} target="_blank" className="article-img stretched-link">
        <img src={thumbnail} alt="" />
      </a>
      <div className="card-body">
        <h5 className="card-title text-truncate">{title}</h5>
        <p className="card-text">
          {description}{" "}
          <a href={url} target="_blank" className="btn btn-sm btn-link p-0">
            more
          </a>
        </p>
      </div>
    </div>
  );
}

export default BlogCard;
