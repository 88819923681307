import React from "react";
import { createRoot } from "react-dom/client";
import "./custom.scss";
import "bootstrap/dist/js/bootstrap";
import ReactDOM from 'react-dom';
import "./index.css";
import { Provider } from 'react-redux';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from 'react-router-dom';
ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>, document.getElementById('root') as HTMLElement);
    
reportWebVitals();
