import logo from "../assets/img/placeholder-logo.png";
interface ReferenceData {
  id: string;
  companyId: string;
  name: string;
  imageUrl: string | null;
  location: string | null;
  size: number | null;
}
function ReferenceCard({ referenceData }: { referenceData: ReferenceData }) {
  const { id, companyId, name, imageUrl, location } = referenceData;
  const referenceLogo = imageUrl
    ? `${imageUrl}`
    : logo;
  return (
    <div className="card featured-card h-100 text-center pe-none">
      <div className="company-logo">
        <img src={referenceLogo} alt="" />
      </div>
      <div className="card-body pb-0">
        <h5 className="card-title text-truncate px-3">{name ? name : ""}</h5>
        <p className="card-text">
          <small className="d-block text-truncate">
            {location ? location : ""}
          </small>
        </p>
      </div>
    </div>
  );
}

export default ReferenceCard;
