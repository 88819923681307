import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "./../../assets/img/placeholder-logo.png";
import "./Banner.scss";
interface BannerProps {
  title?: string | null;
  updated?: string | null;
  tagline?: string | null;
  contactTitle?: string | null;
}
const HeadingBanner: React.FC<BannerProps> = (props) => {
  const videoPath = `./../../assets/videos/company-banner.mp4`;
  const bannerTitle = props.title
    ? props.title
    : "Your Pakistani IT companies hub";
  const bannerUpdated = props.updated
    ? "Last updated: " + props.updated
    : props.tagline
    ? props.tagline
    : "Find the right company that will provide you with the best services.";
  const contactTitle = props.contactTitle;
  return (
    <div className="jumbotron text-center">
      <video autoPlay loop muted className="banner-video">
        <source src={videoPath} type="video/mp4" />
      </video>
      <div
        className={`container ${contactTitle ? "py-4 mt-4" : ""}
        ${props.updated ? "py-4 mt-4" : ""}`}
      >
        <h1>
          {contactTitle ? (
            <>
              {contactTitle} <span className="text-primary"> Alva</span>
            </>
          ) : (
            bannerTitle
          )}
        </h1>

        <p className="col-lg-8 mx-auto fs-5">
          {contactTitle ? "" : bannerUpdated}
        </p>
      </div>
    </div>
  );
};

export default HeadingBanner;
