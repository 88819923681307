enum IndustryType {
  "Cybersecurity" = 1,
  "E-commerce" = 2,
  "Foodtech" = 3,
  "Fintech" = 4,
  "Healthtech" = 5,
  "IT Outsourcing" = 6,
  "IT Hardware" = 7,
  "IT Education and Training" = 8,
  "Information Technology Services" = 9,
  "Software Development" = 10,
  "Telecommunications" = 11,
}
export default IndustryType;
